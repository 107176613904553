import { environment } from "../../environments";

export const constant = {
  BASE_URL: environment.apiBaseUrl,
  thunkStatus: {
    PENDING: "pending",
    FULFILLED: "fulfilled",
    REJECTED: "rejected",
  },
};
